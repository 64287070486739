import { Link } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.header`
  padding: 24px;

  @media screen and (min-width: 481px) {
    padding: 48px;
  }
`;

const Title = styled(Link)`
  color: #11a536;
  font-size: 32px;
  line-height: 38px;
  text-decoration: none;
  font-weight: 700;

  @media screen and (min-width: 481px) {
    font-size: 48px;
    line-height: 56px;
  }
`;

const WebsiteUrl = styled.p`
  color: rgba(17, 165, 54, 0.5);
  font-size: 16px;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;

  @media screen and (min-width: 481px) {
    font-size: 24px;
    margin-bottom: 24px;
  }
`;

export const Header = (): JSX.Element => {
  return (
    <Wrapper>
      <WebsiteUrl>wfpb.blog</WebsiteUrl>
      <Title to="/">The Whole-Foods, Plant-Based Blog</Title>
    </Wrapper>
  );
};
