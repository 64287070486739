import React from "react"
import { mdx } from "@mdx-js/react"

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Why Whole-Food Plant-Based (WFPB)?`}</h1>
    <p>{`I've been an on-and-off vegan for over 12 years, and fully 'on the wagon' so to speak for the past 4`}{`½`}{` years.`}</p>
    <p>{`In the early days there wasn't a restaurant in sight that would serve a good vegan meal and cooking at home really meant cooking everything from scratch. By the late 2010s every supermarket had vegan food in almost every aisle and restaurants from the small independent to the mega chain were seeing the value of having great vegan options on their menus.`}</p>
    <p>{`This has been a huge win for those wanting to reduce their meat and dairy consumption for environmental or ethical reasons. The problem is that health-wise we've actually taken several steps backwards as more of these quick and easy vegan foods appear on the market. Many meat and dairy alternatives are pumped full of coconut oil and processed sugar and the go-to vegan option for restaurants is often a burger with a collection of deep-fried or coconut-oil-cheese covered sides.`}</p>
    <p>{`So what can we do? The simple answer is getting back to basics, to whole grains, fruits and vegetables; foods that aren't processed to make them addictive to our tastebuds and backed by huge advertising campaigns and only serve to fuel the profits of large corporations that couldn't care less about your health or well-being.`}</p>
    <p>{`The answer is a whole-foods, plant-based lifestyle.`}</p>
    <br />
    <Link to="/post/what-is-a-whole-foods-plant-based-lifestyle" mdxType="Link">What is a whole-foods, plant-based lifestyle?</Link>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;