import React from "react"
import { mdx } from "@mdx-js/react"

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom';
import { Image } from '../image/Image';
import { ImageCaption } from '../image/ImageCaption';
import { ImageWrapper } from '../image/ImageWrapper';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Day Two`}</h1>
    <p>{`Just under 2600 steps today after an evening stroll, poor effort really but a longer woodland hike is planned for tomorrow. Great day for food though with three warm and filling meals while the rain pounded down outside.`}</p>
    <p>{`If you're adjusting to this way of eating from a diet without much fibre, you will very likely feel a bit bloated for a week or two. Stick with it - your body just needs time to adjust.`}</p>
    <ImageWrapper mdxType="ImageWrapper">
  <Image srcSet="/day2-breakfast.webp 4x" mdxType="Image" />
  <ImageCaption mdxType="ImageCaption">Day 2: Breakfast of porridge with banana, blueberries and raspberries</ImageCaption>
    </ImageWrapper>
    <ImageWrapper mdxType="ImageWrapper">
  <Image srcSet="/day2-lunch.webp 4x" mdxType="Image" />
  <ImageCaption mdxType="ImageCaption">Day 2: Lunch of dal and rice with salad (I omitted the hemp seeds and hummus on mine)</ImageCaption>
    </ImageWrapper>
    <ImageWrapper mdxType="ImageWrapper">
  <Image srcSet="/day2-dinner.webp 4x" mdxType="Image" />
  <ImageCaption mdxType="ImageCaption">Day 2: Dinner of bean and vegetable chilli.</ImageCaption>
    </ImageWrapper>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;