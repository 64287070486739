import React from "react"
import { mdx } from "@mdx-js/react"

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom';
import { Image } from '../image/Image';
import { ImageCaption } from '../image/ImageCaption';
import { ImageWrapper } from '../image/ImageWrapper';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Day One`}</h1>
    <p>{`A rather lazy New Year's day overall but we did get out for a walk this afternoon and clocked up around 8500 steps for the day.`}</p>
    <ImageWrapper mdxType="ImageWrapper">
  <Image srcSet="/day1-breakfast.webp 4x" mdxType="Image" />
  <ImageCaption mdxType="ImageCaption">
    Day 1: Breakfast of spiced &amp; roasted potatoes; mushrooms with spinach, garlic, and hemp; and baked beans
  </ImageCaption>
    </ImageWrapper>
    <p>{`While my partner cooked us the perfect post-NYE breakfast (shown above), I took the opportunity to raid the fridge, freezer, and cupboards for anything even remotely un-aligned to our new lifestyle. Nothing was spared save some chocolate and biscuits to be given away. Vegan Mayo, Ice Cream, Vegan Cheese, Beers, Custard, Mince Pies, Strudel all out. Whole grains, fruits and vegetables in.`}</p>
    <p>{`I almost felt sadness in some of the tasty treats I would never get to eat and in some ways it was wasteful, but behind that was a feeling of satisfaction that we were taking the right steps towards a healthier diet. Maybe all that junk isn't `}<em parentName="p">{`actually`}</em>{` food anyway...`}</p>
    <p>{`After the afternoon walk I whipped up a huge batch of dal (aka daal or dhal) with onions and carrot and lots of delicious spices. I'd worked up quite a hunger by this point but definitely went too big on the portion size and promptly fell into a food coma not long after eating.`}</p>
    <ImageWrapper mdxType="ImageWrapper">
  <Image srcSet="/day1-dinner.webp 4x" mdxType="Image" />
  <ImageCaption mdxType="ImageCaption">Day 1: Dinner of dahl with wholegrain basmati and wild rice</ImageCaption>
    </ImageWrapper>
    <p>{`All in all a successful day, and we have some portions of dal for lunch tomorrow and some more in the freezer too. Prep is key in avoiding the temptation of junk food, so we'll be doing some more meal prop tomorrow.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;