import React from "react"
import { mdx } from "@mdx-js/react"

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom';
import { Image } from '../image/Image';
import { ImageCaption } from '../image/ImageCaption';
import { ImageWrapper } from '../image/ImageWrapper';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Day Three`}</h1>
    <p>{`A lovely afternoon walk in the woods today, clocking up 13725 steps and lots of hills.`}</p>
    <p>{`Was craving something sweet in the evening, a good suggestion from my sister was to eat a teaspoon of jam! Hits the craving on the head without eating something big or full of fat too.`}</p>
    <ImageWrapper mdxType="ImageWrapper">
  <Image srcSet="/day3-breakfast.webp 4x" mdxType="Image" />
  <ImageCaption mdxType="ImageCaption">Day 3: Breakfast of porridge with banana, blueberries and raspberries</ImageCaption>
    </ImageWrapper>
    <ImageWrapper mdxType="ImageWrapper">
  <Image srcSet="/day3-walk.webp 4x" mdxType="Image" />
  <ImageCaption mdxType="ImageCaption">Day 3: Beautiful fungi growing on a fallen tree</ImageCaption>
    </ImageWrapper>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;