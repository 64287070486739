import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Header } from '../header/Header';

const PostWrapper = styled.article`
  color: #41433a;
  padding: 24px 48px;

  @media screen and (max-width: 480px) {
    padding: 12px 24px;
  }
`;

export const Home = (): JSX.Element => {
  return (
    <>
      <Header />

      <PostWrapper>
        <Link to="/post/blog-2022-01-01-day-one">Day One</Link>
        <br />
        <Link to="/post/blog-2022-01-02-day-two">Day Two</Link>
        <br />
        <Link to="/post/blog-2022-01-03-day-three">Day Three</Link>
        <br />
        <Link to="/post/blog-2022-01-04-day-four">Day Four</Link>
        <br />
        <br />
        <Link to="/post/my-goals">My Goals</Link>
        <br />
        <Link to="/statistics">Statistics</Link>
        <br />
        <Link to="/post/why-whole-foods-plant-based">Why Whole-Foods, Plant-Based?</Link>
        <br />
        <Link to="/post/what-is-a-whole-foods-plant-based-lifestyle">
          What is a Whole-Foods, Plant-Based lifestyle?
        </Link>
        <br />
        <Link to="/post/resources">Resources</Link>
      </PostWrapper>
    </>
  );
};
