import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';

import { Header } from '../header/Header';

const TitleWrapper = styled.div`
  margin: 24px;

  @media screen and (min-width: 481px) {
    margin: 48px;
  }
`;

const ChartWrapper = styled.div`
  max-width: 100%;
  margin: 24px;

  @media screen and (min-width: 481px) {
    margin: 48px;
  }
`;

const ChartTitle = styled.div`
  text-align: center;
  margin: 24px;

  @media screen and (min-width: 481px) {
    margin: 48px;
  }
`;

const initialWeight = 202.6;

const data = [
  {
    actualArmCm: 33,
    actualBodyWaterLb: 103.9,
    actualBoneLb: 7.2,
    actualChestCm: 108,
    actualFatLb: 57.8,
    actualHipsCm: 100,
    actualLb: 202.6,
    actualMuscleLb: 137.5,
    actualThighCm: 55,
    actualWaistCm: 106,
    date: '2022-01-01',
    perceivedEnergy: 6,
    perceivedHappiness: 8,
  },
  {
    actualArmCm: null,
    actualBodyWaterLb: 102.4,
    actualBoneLb: 7.1,
    actualChestCm: null,
    actualFatLb: 59.1,
    actualHipsCm: null,
    actualLb: 201.0,
    actualMuscleLb: 134.8,
    actualThighCm: null,
    actualWaistCm: null,
    date: '2022-01-02',
    perceivedEnergy: 7,
    perceivedHappiness: 8,
  },
  {
    actualArmCm: null,
    actualBodyWaterLb: 101.7,
    actualBoneLb: 7.1,
    actualChestCm: null,
    actualFatLb: 58.6,
    actualHipsCm: null,
    actualLb: 199.5,
    actualMuscleLb: 133.8,
    actualThighCm: null,
    actualWaistCm: null,
    date: '2022-01-03',
    perceivedEnergy: 7,
    perceivedHappiness: 8,
  },
  {
    actualArmCm: null,
    actualBodyWaterLb: 101.1,
    actualBoneLb: 7.0,
    actualChestCm: null,
    actualFatLb: 58.4,
    actualHipsCm: null,
    actualLb: 198.5,
    actualMuscleLb: 133.0,
    actualThighCm: null,
    actualWaistCm: null,
    date: '2022-01-04',
    perceivedEnergy: 4,
    perceivedHappiness: 6,
  },
  {
    actualArmCm: null,
    actualBodyWaterLb: 100.8,
    actualBoneLb: 7.0,
    actualChestCm: null,
    actualFatLb: 58.2,
    actualHipsCm: null,
    actualLb: 197.7,
    actualMuscleLb: 132.5,
    actualThighCm: null,
    actualWaistCm: null,
    date: '2022-01-05',
    perceivedEnergy: 7,
    perceivedHappiness: 7,
  },
  {
    actualArmCm: null,
    actualBodyWaterLb: 99.9,
    actualBoneLb: 7.0,
    actualChestCm: null,
    actualFatLb: 59.0,
    actualHipsCm: null,
    actualLb: 196.9,
    actualMuscleLb: 130.9,
    actualThighCm: null,
    actualWaistCm: null,
    date: '2022-01-06',
    perceivedEnergy: 7,
    perceivedHappiness: 8,
  },
  {
    actualArmCm: null,
    actualBodyWaterLb: 100.8,
    actualBoneLb: 7.0,
    actualChestCm: null,
    actualFatLb: 57.5,
    actualHipsCm: null,
    actualLb: 197.4,
    actualMuscleLb: 132.7,
    actualThighCm: null,
    actualWaistCm: null,
    date: '2022-01-07',
    perceivedEnergy: 7,
    perceivedHappiness: 8,
  },
  {
    actualArmCm: null,
    actualBodyWaterLb: 99.5,
    actualBoneLb: 6.9,
    actualChestCm: null,
    actualFatLb: 60.5,
    actualHipsCm: null,
    actualLb: 197.3,
    actualMuscleLb: 129.8,
    actualThighCm: null,
    actualWaistCm: null,
    date: '2022-01-08',
    perceivedEnergy: 7,
    perceivedHappiness: 8,
  },
];

const fullData = data.map((item, index) => {
  const weightLossAimPerMonthLb = 8;
  const year = 2022;
  const month = 1;
  const daysInMonth = new Date(year, month, 0).getDate();
  const targetLb = (initialWeight - (weightLossAimPerMonthLb / (daysInMonth - 1)) * index).toFixed(1);

  return {
    ...item,
    targetLb,
  };
});

const MIN_WEIGHT = 190;
const MAX_WEIGHT = 210;

export const Statistics = (): JSX.Element => {
  return (
    <>
      <Header />
      <TitleWrapper>
        <h2>Statistics</h2>
      </TitleWrapper>
      <ChartWrapper>
        <ChartTitle>Weight (lb)</ChartTitle>
        <ResponsiveContainer debounce={1000} width="100%" height={360}>
          <LineChart
            width={720}
            height={360}
            data={fullData}
            margin={{
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis width={40} domain={[MIN_WEIGHT, MAX_WEIGHT]} />
            <Tooltip />
            <Legend />
            <Line type="monotone" connectNulls name="Actual Weight (lb)" dataKey="actualLb" stroke="#8ac926" />
            <Line type="monotone" connectNulls name="Target Weight (lb)" dataKey="targetLb" stroke="#757575" />
          </LineChart>
        </ResponsiveContainer>
      </ChartWrapper>
      <ChartWrapper>
        <ChartTitle>Body mass (lb)</ChartTitle>
        <ResponsiveContainer debounce={1000} width="100%" height={360}>
          <ComposedChart
            width={720}
            height={360}
            data={fullData}
            margin={{
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis width={40} />
            <Tooltip />
            <Legend />
            <Bar type="monotone" stackId="weight" name="Bone (lb)" dataKey="actualBoneLb" fill="#ffca3a" />
            <Bar type="monotone" stackId="weight" name="Fat (lb)" dataKey="actualFatLb" fill="#8ac926" />
            <Bar type="monotone" stackId="weight" name="Muscle (lb)" dataKey="actualMuscleLb" fill="#1982c4" />
            <Line type="monotone" connectNulls name="Body Water (lb)" dataKey="actualBodyWaterLb" stroke="#ff595e" />
          </ComposedChart>
        </ResponsiveContainer>
      </ChartWrapper>
      <ChartWrapper>
        <ChartTitle>Measurements (cm)</ChartTitle>
        <ResponsiveContainer debounce={1000} width="100%" height={360}>
          <LineChart
            width={720}
            height={360}
            data={fullData}
            margin={{
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" padding={{ left: 0 }} />
            <YAxis width={30} />
            <Tooltip />
            <Legend />
            <Line type="monotone" connectNulls name="Arm (cm)" dataKey="actualArmCm" stroke="#ff595e" />
            <Line type="monotone" connectNulls name="Chest (cm)" dataKey="actualChestCm" stroke="#ffca3a" />
            <Line type="monotone" connectNulls name="Hips (cm)" dataKey="actualHipsCm" stroke="#8ac926" />
            <Line type="monotone" connectNulls name="Thigh (cm)" dataKey="actualThighCm" stroke="#1982c4" />
            <Line type="monotone" connectNulls name="Waist (cm)" dataKey="actualWaistCm" stroke="#6a4c93" />
          </LineChart>
        </ResponsiveContainer>
      </ChartWrapper>
      <ChartWrapper>
        <ChartTitle>Mood</ChartTitle>
        <ResponsiveContainer debounce={1000} width="100%" height={360}>
          <LineChart
            width={720}
            height={360}
            data={fullData}
            margin={{
              bottom: 0,
              left: 0,
              right: 0,
              top: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis width={40} />
            <Tooltip />
            <Legend />
            <Line type="monotone" connectNulls name="Energy" dataKey="perceivedEnergy" stroke="#8ac926" />
            <Line type="monotone" connectNulls name="Happiness" dataKey="perceivedHappiness" stroke="#1982c4" />
          </LineChart>
        </ResponsiveContainer>
      </ChartWrapper>
    </>
  );
};
