import React from "react"
import { mdx } from "@mdx-js/react"

/* @jsxRuntime classic */
/* @jsx mdx */



const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Resources`}</h1>
    <h2>{`McDougall`}</h2>
    <ul>
      <li parentName="ul">{`Recipes: `}<a parentName="li" {...{
          "href": "https://www.drmcdougall.com/recipes/"
        }}>{`https://www.drmcdougall.com/recipes/`}</a></li>
      <li parentName="ul">{`Free McDougall program: `}<a parentName="li" {...{
          "href": "https://www.drmcdougall.com/articles/free-mcdougall-program/program-overview/"
        }}>{`https://www.drmcdougall.com/articles/free-mcdougall-program/program-overview/`}</a></li>
      <li parentName="ul">{`Maximum Weight Loss checklist: `}<a parentName="li" {...{
          "href": "https://www.drmcdougall.com/newsletters/mcdougall-program-maximum-weight-loss-10-point-checklist-and-recipes/"
        }}>{`https://www.drmcdougall.com/newsletters/mcdougall-program-maximum-weight-loss-10-point-checklist-and-recipes/`}</a></li>
    </ul>
    <h2>{`Forks over Knives`}</h2>
    <ul>
      <li parentName="ul">{`The Forks over Knives Film: `}<a parentName="li" {...{
          "href": "https://www.forksoverknives.com/the-film/"
        }}>{`https://www.forksoverknives.com/the-film/`}</a></li>
      <li parentName="ul">{`Recipes: `}<a parentName="li" {...{
          "href": "https://www.forksoverknives.com/recipes/"
        }}>{`https://www.forksoverknives.com/recipes/`}</a></li>
    </ul>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;