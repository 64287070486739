import React from "react"
import { mdx } from "@mdx-js/react"

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom';
import { Image } from '../image/Image';
import { ImageCaption } from '../image/ImageCaption';
import { ImageWrapper } from '../image/ImageWrapper';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Day Four`}</h1>
    <p>{`Back to work and I didn't get much sleep so very much lacking in energy today. An evening of chores and a trip to the supermarket left me with a rather pitiful 1853 steps.`}</p>
    <p>{`Breakfast was overnight oats with vanilla and cinnamon and I added some fresh blueberries and raspberries before eating. Lunch was leftover dal with quinoa and dinner was a stir-fry with rice. I also snacked on wholegrain rice crackers and fruit.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;