import Blog20220101DayOne from './blog-2022-01-01-day-one.mdx';
import Blog20220102DayTwo from './blog-2022-01-02-day-two.mdx';
import Blog20220103DayThree from './blog-2022-01-03-day-three.mdx';
import Blog20220104DayFour from './blog-2022-01-04-day-four.mdx';
import MyGoals from './my-goals.mdx';
import NotFound from './not-found.mdx';
import Resources from './resources.mdx';
import WhatIsAWholeFoodsPlantBasedLifestyle from './what-is-a-whole-foods-plant-based-lifestyle.mdx';
import WhyWholeFoodsPlantBased from './why-whole-foods-plant-based.mdx';

export const posts: Record<string, (props: any) => JSX.Element> = {
  'blog-2022-01-01-day-one': Blog20220101DayOne,
  'blog-2022-01-02-day-two': Blog20220102DayTwo,
  'blog-2022-01-03-day-three': Blog20220103DayThree,
  'blog-2022-01-04-day-four': Blog20220104DayFour,
  'my-goals': MyGoals,
  'not-found': NotFound,
  'resources': Resources,
  'what-is-a-whole-foods-plant-based-lifestyle': WhatIsAWholeFoodsPlantBasedLifestyle,
  'why-whole-foods-plant-based': WhyWholeFoodsPlantBased,
};
