import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Home } from './components/routes/Home';
import { Post } from './components/routes/Post';
import { Statistics } from './components/routes/Statistics';

export const App = (): JSX.Element => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/post/:slug" element={<Post />} />
          <Route path="/statistics" element={<Statistics />} />
          <Route path="/" element={<Home />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
