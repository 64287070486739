import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { Header } from '../header/Header';
import { posts } from '../posts';

const PostWrapper = styled.article`
  color: #41433a;
  padding: 24px 48px;
  max-width: 36em;

  @media screen and (max-width: 480px) {
    padding: 12px 24px;
  }

  > * {
    padding: 0;
    margin: 0;
  }

  > * + * {
    margin-top: 24px;

    @media screen and (max-width: 480px) {
      margin-top: 12px;
    }
  }
`;

export const Post = (): JSX.Element => {
  const { slug } = useParams();

  const { 'not-found': NotFound } = posts;
  const PostContent = (slug === undefined ? undefined : posts[slug]) ?? NotFound;

  return (
    <>
      <Header />
      <PostWrapper>{PostContent ? <PostContent /> : null}</PostWrapper>
    </>
  );
};
