import styled from 'styled-components';

export const Image = styled.img`
  display: block;
  object-fit: cover;
  border-radius: 16px;
  width: auto;
  height: auto;
  max-height: 100%;
  max-width: 100%;
`;
