import React from "react"
import { mdx } from "@mdx-js/react"

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom';
const initialWeightLb = 202.6;


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`My Goals`}</h1>
    <p>{`I've already been following a vegan diet for a number of years so this Veganuary 2022 I plan to stop eating processed vegan junk food. My goal is to lose some weight and improve my general health and fitness. Initially I'll be eating high-carb, low-fat to maximise weight loss (basically keeping the nuts, seeds, and dried fruit to a minimum). I'll also be aiming to get around 6500 steps in per day (~200000 per month), which isn't a huge amount but with the past couple of years of working from home I've become rather sedentary during the week.`}</p>
    <p>{`My expectation is roughly 8 lb weight loss per month for a total weight loss of 56 lb by the end of July 2022.`}</p>
    <h2>{`31 January 2022`}</h2>
    <p>Goal weight: {initialWeightLb - 8 * 1} lb</p>
    <h2>{`28 February 2022`}</h2>
    <p>Goal weight: {initialWeightLb - 8 * 2} lb</p>
    <h2>{`31 March 2022`}</h2>
    <p>Goal weight: {initialWeightLb - 8 * 3} lb</p>
    <h2>{`30 April 2022`}</h2>
    <p>Goal weight: {initialWeightLb - 8 * 4} lb</p>
    <h2>{`31 May 2022`}</h2>
    <p>Goal weight: {initialWeightLb - 8 * 5} lb</p>
    <h2>{`30 June 2022`}</h2>
    <p>Goal weight: {initialWeightLb - 8 * 6} lb</p>
    <h2>{`31 July 2022`}</h2>
    <p>Goal weight: {initialWeightLb - 8 * 7} lb</p>
    <br />
    <Link to="/post/what-is-a-whole-foods-plant-based-lifestyle" mdxType="Link">What is a whole-foods, plant-based lifestyle?</Link>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;