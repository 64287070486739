import React from "react"
import { mdx } from "@mdx-js/react"

/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'react-router-dom';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`What is a Whole-Foods, Plant-Based lifestyle?`}</h1>
    <p>{`The basis of a Whole-Foods, Plant-Based diet is eating starches and whole grains, fruits, vegetables, nuts, and seeds - all foods that promote health. Conversely it means eliminating processed foods, meat, fish, and dairy - these foods are the causes of many diseases from cancer to diabetes and heart disease.`}</p>
    <p>{`Rather than seeing this as in some way restrictive, I prefer adopting a mindset change as to what actually classes as food. A potato, well that's clearly food. That same potato deep fried, I don't think that's food anymore. A shiny packet of oreos? Not food. A bottle of olive oil, not food. Red and yellow peppers, looks like food to me!`}</p>
    <p>{`It's a subtle thing but it's helped me in the past to do this reframing, because it makes it easy to make good choices at the supermarket - as a general rule if it has more than a couple of ingredients it's probably not worth buying.`}</p>
    <br />
    <Link to="/post/why-whole-foods-plant-based" mdxType="Link">Why Whole-Foods, Plant-Based?</Link>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;